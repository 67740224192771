import dynamic       from "next/dynamic";
import { useRouter } from "next/router";

import { useGetIsBlockedByStreamer } from "_common/hooks/global";
import { useGetPipModeStatus }       from "_common/hooks/player";
import { isPageRoute }               from "_common/utils";

const PIPPlayer = dynamic ( () => import ( "_components/organisms/PIP" ) );

const PIP = () => {
	const router              = useRouter ();
	const pipModeStatus       = useGetPipModeStatus ();
	const isBlockedByStreamer = useGetIsBlockedByStreamer ();

	const checkPageRoute = page => {
		return isPageRoute ( { router, page } );
	};

	if ( checkPageRoute ( "studio" ) || checkPageRoute ( "reels" ) )
		return null;

	if ( isBlockedByStreamer || !pipModeStatus )
		return null;

	return <PIPPlayer />;
};

export default PIP;
